/**
 * External links used in the article post text. I decided to place them in a separate
 * file so they could be imported and passed to an component that allowed the links
 * to fit in better with the text. Instead of disrupting the flow in the mdx, and making
 * reading the markdown difficult
 * */

export const links = {
  resetCode: 'https://meyerweb.com/eric/tools/css/reset/reset.css',
  resetPost: 'http://meyerweb.com/eric/thoughts/2007/04/12/reset-styles',
  resetEclipsed:
    'https://meyerweb.com/eric/thoughts/2007/04/18/reset-reasoning/#comment-3580569',
  normalize: 'http://necolas.github.io/normalize.css',
};
