import React from 'react';
import styled from 'styled-components';

const TextLink = ({ className, children, href }) => {
  return (
    <a
      className={className}
      href={href}
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </a>
  );
};

export default styled(TextLink)`
  color: var(--darkestEarth);
  font-weight: bolder;
  text-decoration: underline;
  text-decoration-color: var(--cardEarth);
  transition: var(--slowTransition);
  &:hover {
    color: var(--primaryColor);
    text-decoration-color: var(--primaryColor);
  }
`;
