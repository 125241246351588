import React from 'react';
import styled from 'styled-components';

const StyledItemGrid = ({ children, className }) => {
  return <div className={className}>{children}</div>;
};

export const ItemGrid = styled(StyledItemGrid)`
  display: grid;
  grid-gap: 2rem;
  padding: 1rem 0 1.5rem 0;
  align-items: center;

  @media (min-width: 768px) {
    grid-template-columns: ${props =>
      props.columns ? props.columns : '1fr 1fr 1fr'};
  }
`;
