import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';

const ImageTall = ({ className, alt, fluid }) => {
  return <Img className={className} fluid={fluid} alt={alt} />;
};

export default styled(ImageTall)`
  margin: 1rem auto;
  max-width: 90%;

  @media (min-width: 576px) {
    max-width: 85%;
  }

  @media (min-width: 768px) {
    max-width: 70%;
  }

  @media (min-width: 992px) {
    max-width: 55%;
    /* margin: 1rem 0; */
  }
`;
