import React from 'react';
import styled from 'styled-components';

const Card = ({ className, children }) => {
  return <div className={className}>{children}</div>;
};

export default styled(Card)`
  width: ${props => (props.width ? props.width : '100%')};
  height: ${props => (props.height ? props.height : '6.25rem')};
  background: ${props => (props.background ? props.background : '#eee')};

  @media (min-width: 768px) {
    height: ${props => (props.height ? props.height : '11rem')};
  }
`;
