import TextLink from "../../../../src/posts/assets/components/TextLink.js";
import ImageWide from "../../../../src/posts/assets/components/ImageWide.js";
import { Snippet, Highlight } from "../../../../src/posts/assets/components/Snippet.js";
import React from 'react';
export default {
  TextLink,
  ImageWide,
  Snippet,
  Highlight,
  React
};