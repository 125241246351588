import React from 'react';
import styled from 'styled-components';

const StyledSnippet = ({ children, className }) => {
  return <span className={className}>{children}</span>;
};

export const Highlight = styled(StyledSnippet)`
  color: var(--mainYellow);
`;

export const Snippet = styled(StyledSnippet)`
  padding: 0.25rem 0.5rem;
  background: var(--cardEarth);
  border-radius: 0.5rem;
  font-size: 1.15rem;
  color: white;
`;

export const CodeLabel = styled(Snippet)`
  position: absolute;
  left: 0%;
  z-index: 1;
  transform: translateY(-37px);
  font-size: 0.9rem;
  padding: 0.3rem 0.25rem 0rem 0.25rem;
  border-radius: 0 0 0.5rem 0;
`;
