import { Snippet, Highlight, CodeLabel } from "../../../../src/posts/assets/components/Snippet.js";
import TextLink from "../../../../src/posts/assets/components/TextLink.js";
import { links } from "../../../../src/posts/css/2019-09-04-normalize-vs-reset/links.js";
import React from 'react';
export default {
  Snippet,
  Highlight,
  CodeLabel,
  TextLink,
  links,
  React
};