import data from "../../../../src/posts/assets/data/adv-gradient-data.js";
import Card from "../../../../src/posts/assets/components/Card.js";
import ImageTall from "../../../../src/posts/assets/components/ImageTall.js";
import TextLink from "../../../../src/posts/assets/components/TextLink.js";
import { CodeLabel, Snippet, Highlight } from "../../../../src/posts/assets/components/Snippet.js";
import { ItemGrid } from "../../../../src/posts/assets/components/ItemGrid.js";
import React from 'react';
export default {
  data,
  Card,
  ImageTall,
  TextLink,
  CodeLabel,
  Snippet,
  Highlight,
  ItemGrid,
  React
};