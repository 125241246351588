import Img from 'gatsby-image';
import { Snippet, Highlight, CodeLabel } from "../../../../src/posts/assets/components/Snippet.js";
import ImageWide from "../../../../src/posts/assets/components/ImageWide.js";
import React from 'react';
export default {
  Img,
  Snippet,
  Highlight,
  CodeLabel,
  ImageWide,
  React
};