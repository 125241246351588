/**
 * External links used in the article post text. I decided to place them in a separate
 * file so they could be imported and passed to an component that allowed the links
 * to fit in better with the text. Instead of disrupting the flow in the mdx, and making
 * reading the markdown difficult
 * */

export const links = {
  support: 'https://caniuse.com/#feat=css-marker-pseudo',
};
