import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';

const ImageTall = ({ className, alt, fluid }) => {
  return <Img className={className} fluid={fluid} alt={alt} />;
};

export default styled(ImageTall)`
  margin: 2rem auto;
  max-width: ${props => (props.maxWidth ? props.maxWidth : '70%')};

  @media (min-width: 576px) {
    max-width: ${props => (props.maxWidth ? props.maxWidth : '65%')};
  }

  @media (min-width: 768px) {
    max-width: ${props => (props.maxWidth ? props.maxWidth : '60%')};
  }

  @media (min-width: 992px) {
    max-width: ${props => (props.maxWidth ? props.maxWidth : '35%')};
  }
`;
